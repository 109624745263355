@use 'sass:list';
@use 'sass:math';
@use '../global/icons/mixins' as *;
@forward '../global/icons/mixins';
@use './variables' as *;

@mixin link-base {
  @include fontSize(14);
  position: relative;
  color: var(--color-link-primary);
  font-weight: var(--sans-bold);
  font-family: var(--body-font);
  line-height: 20px;
  text-decoration: none;

  [data-base-text='true'] & {
    font-size: inherit;
    line-height: inherit;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--color-link-primary-hover);
  }
}

@mixin link-styles {
  a:not([class]),
  .fake-link,
  .t-link,
  .s-link {
    color: var(--color-link-primary);
    text-decoration: underline;
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--color-link-primary-hover);
    }
  }
}

@mixin negativable {
  &.negative {
    color: var(--c_white);

    &::before {
      height: 2px;
      background: var(--c_white);
    }

    &:active {
      color: var(--c_white);

      &::before {
        background: var(--c_white);
      }
    }
  }
}

@mixin downable {
  &.l-down {
    @include icon('arrow-down', after, 'xs') {
      margin-left: 5px;
    }
    transition: transform 0.3s ease;

    &.l-up::after {
      content: '';
    }

    &.l-up {
      @include icon('arrow-up', after, 'xs') {
      }
    }

    &.active {
      @include icon('arrow-up-fat') {
        color: var(--c_primary);
      }
      color: $c_dark_grey;
    }

    &:hover,
    &:focus {
      &::before {
        display: none;
      }
    }
  }
}

@mixin rightable {
  &.l-right {
    @include icon('arrow-right', after, 'xs') {
      margin-left: 8px;
    }
  }
}

@mixin bg-transition {
  transition: $bg-transition;
}

// -------------------------------------
//   font-size to rems
// -------------------------------------
@mixin fontSize($sizeValue: 16) {
  font-size: $sizeValue + px;
  font-size: calc((#{$sizeValue} / var(--font-base)) * 1rem);
}

// TODO PY06050-3
// The two mixins have been left unchanged until we can verify that they can be unified into one.
@mixin fontSerifBold() {
  font-family: var(--title-font);
}

@mixin fontSerifSemiBold() {
  font-family: var(--title-font);
}

@mixin fontSansSemiBold() {
  font-weight: var(--sans-semi-bold);
  font-family: var(--body-font);
}

@mixin fontSansBold() {
  font-weight: var(--sans-bold);
  font-family: var(--body-font);
}

@mixin fontSansRegular() {
  font-weight: var(--sans-regular);
  font-family: var(--body-font);
}

@mixin lineHeight($sizeValue: 16) {
  line-height: $sizeValue + px;
}

/**
 * Mixin for block title styles.
 *
 * This mixin applies styles for block titles, including font-family,
 * font-weight, and color. It also adjusts font size and line height
 * for mobile and tablet/desktop screens.
 *
 * @param {Number} $mobile-font-size - Font size for mobile screens. Default is 32px.
 * @param {Number} $desktop-font-size - Font size for tablet and desktop screens. Default is 32px.
 *
 * @example -> Using the blockTitle mixin with default font sizes
 * .block-title {
 *   @include blockTitle;
 * }
 *
 * @example -> Using the blockTitle mixin with custom font sizes
 * .block-title-custom {
 *   @include blockTitle(28, 36);
 * }
 */
@mixin blockTitle($mobile-font-size: 22, $desktop-font-size: 32) {
  font-family: var(--title-font);
  font-weight: var(--weight-title-font);
  color: var(--color-title-font);

  @include mobileStyles {
    @include fontSize($mobile-font-size);
    line-height: normal;
  }
  @include commonTabletDesktop {
    @include fontSize($desktop-font-size);
    line-height: var(--line-height);
  }
}

/**
 * Mixin for highlighted title styles.
 *
 * This mixin applies styles for highlighted titles, including font-family
 * and font-weight. It adjusts the line height for mobile and tablet/desktop screens.
 *
 * @example -> Using the titleHighlighted mixin
 * .highlighted-title {
 *   @include titleHighlighted;
 * }
 */
@mixin titleHighlighted() {
  font-family: var(--title-font);
  font-weight: var(--weight-title-font);
}

// // ------------------------------------------
// //   Mobile buttons
// // ------------------------------------------

@mixin button($include-border-radius: false, $radius: 30) {
  @include fontSize(14);
  display: inline-block;
  min-width: 122px;
  padding: 10px;
  border-radius: 0;
  font-weight: var(--sans-bold);
  font-family: var(--body-font);
  line-height: 20px;
  text-align: center;
  transition-duration: 0.3s;
  transition-property: color, background-color;
  @if $include-border-radius {
    border-radius: $radius + px;
  }

  &.disabled,
  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.hidden {
    display: none;
  }
}

@mixin connect-btn {
  @include fontSansBold;
  @include fontSize(14);
  position: relative;
  display: inline-block;
  min-width: 250px;
  padding: 10px 27px 10px 59px;
  border: 1px solid;
  border-radius: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 9%);
  background: var(--c_white);
  line-height: 20px;

  &::after {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    font-size: 16px;
    line-height: 42px !important;
    text-align: center;
  }

  &.facebook {
    @include icon(facebook) {
      background: #3b5998;
      color: var(--c_white);
    }
    border-color: #3b5998;
    color: #3b5998;
  }

  &.google {
    @include icon(google) {
      background: #d62d20;
      color: var(--c_white);
    }
    border-color: #d62d20;
    color: #d62d20;
  }

  .connect {
    display: inline-block;
  }

  .disconnect {
    display: none;
  }

  &.connected {
    padding: 10px 59px 10px 27px;

    &::after {
      left: auto;
      right: -2px;
      border: 1px solid var(--c_grey_200);
    }

    &.facebook {
      background: #3b5998;
      color: var(--c_white);

      &::after {
        background: var(--c_white);
        color: #3b5998;
      }
    }

    &.google {
      background: #d62d20;
      color: var(--c_white);

      &::after {
        background: var(--c_white);
        color: #d62d20;
      }
    }

    .connect {
      display: none;
    }

    .disconnect {
      display: inline-block;
    }
  }
}

@mixin tripadvisor-cal {
  .tripadvisor-cal {
    display: inline-block;

    &.desktop-style {
      color: var(--c_primary);
    }

    &.mobile-style {
      color: var(--c_dark_grey);

      .basic-tooltip {
        max-width: 225px;
      }

      .s {
        color: var(--c_primary);
      }
    }
  }
}

// -------------------------------------
//   Separator
// -------------------------------------

@mixin circle-separator {
  .circle-separator {
    border-top: 1px solid var(--c_grey_200);

    &::before {
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 26px;
      height: 1px;
      background: var(--c_white);
    }

    &::after {
      position: absolute;
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      width: 7px;
      height: 7px;
      border: 1px solid var(--c_grey_400);
      border-radius: 50%;
    }
  }
}

@mixin circle-separator-vertical {
  .circle-separator-vertical {
    @include circle-separator;
    border-top: 0;
    border-left: 1px solid var(--c_grey_200);

    &::before {
      top: 50%;
      left: -1px;
      transform: translateY(-50%);
      width: 1px;
      height: 26px;
    }

    &::after {
      top: 50%;
      left: -4px;
      transform: translateY(-50%);
    }
  }
}

// -------------------------------------
//   badge
// -------------------------------------

@mixin badge {
  display: inline-block;
  font-family: var(--body-font);
  cursor: default;
  line-height: var(--f_line_height_normal);
  font-weight: var(--f_font_weight_400);
  &.not-hover {
    pointer-events: none;
  }

  &.uppercase-small {
    @include fontSize(12);
    text-transform: uppercase;
  }
  &.capitalize-medium {
    @include fontSize(14);
  }

  &.round {
    background-color: var(--tag-background-color);
    color: var(--tag-text-color);
    border-radius: 24px;
    padding: 5px 10px;
    &:hover {
      background-color: var(--tag-background-color-hover);
      color: var(--tag-text-color-hover);
    }
  }

  &.beachfront {
    background-color: var(--c_masterbrand_blue_green_500);
    color: var(--c_white);
    pointer-events: none;
  }
  &.city {
    background-color: var(--c_city_blue_700);
    color: var(--c_white);
    pointer-events: none;
  }
  &.heritage {
    background-color: var(--c_heritage_green_200);
    color: var(--c_white);
    pointer-events: none;
  }

  &.is-clickable {
    cursor: pointer;
  }
}

// -------------------------------------
//   Swiper custom pagination
// -------------------------------------

@mixin swiperPaginationCustom {
  .swiper-pagination-custom {
    position: relative;
    bottom: 0;
    width: 100%;
    padding: 14px 0 0;
    margin: 10px auto 0;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: '';
      display: block;
      height: 4px;
      border-radius: 4px;
      background: #dcefed;
    }

    .swiper-pagination-progressbar {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      height: 4px;
      border-radius: 4px;
      background: var(--c_primary);
      transition: left 0.6s ease;
    }
  }
}

@mixin swiperCounter {
  .swiper-counter {
    @include fontSize(16);
    color: rgba(var(--c_turquoise_rgb), 0.5);
    line-height: 26px;
    text-align: right;

    span {
      margin-right: 4px;
      color: var(--c_primary);
    }
  }
}

// // ------------------------------------------
// //   Tripadvisor
// // ------------------------------------------

@mixin tripadvisor-personalization {
  .tripadvisor-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .tripadvisor-logo {
      position: relative;
      width: 55px;
      height: 47px;
    }

    &.mobile-style {
      margin: 10px;
    }

    .tripadvisor-info-content {
      display: flex;
      flex-direction: column;
      margin-left: 10px;

      .content-title {
        color: var(--color-body-font);

        .link {
          color: var(--color-link-primary);
          font-weight: 700;
        }
      }
    }
  }
}

@mixin tripadvisor-cal {
  .tripadvisor-cal {
    display: inline-block;

    &.desktop-style {
      color: var(--c_primary);
    }

    &.mobile-style {
      color: var(--c_dark_grey);

      .basic-tooltip {
        max-width: 225px;
      }

      .s {
        color: var(--c_primary);
      }
    }
  }
}

@mixin svg($name, $file_name, $w: null, $h: null) {
  %icon-#{$name} {
    background-image: url('icons/svg/#{$file_name}.svg');

    .no-svg & {
      background-image: url('icons/png/#{$file_name}.png');
    }
  }

  .icon-#{$name} {
    @extend %ico-#{$name};

    @if $w {
      width: $w;
    }

    @if $h {
      height: $h;
    }
    background-repeat: no-repeat;
  }
}

// // -------------------------------------
// //   linear-gradient
// // -------------------------------------
@mixin segment-gradient($deg, $color, $color_percentage, $white_percentage) {
  background: linear-gradient($deg, $color $color_percentage, var(--c_white) $white_percentage);
}

// -------------------------------------
//   Custom border
//   Example:
//   @include custom-border(top, 2px, red, 100%);
// -------------------------------------
@mixin custom-border($sides: all, $thickness: 1px, $color: black, $width: 100%) {
  @if $sides == all {
    border: $thickness solid $color;
  } @else {
    @if list.index($sides, top) {
      border-top: $thickness solid $color;
    }

    @if list.index($sides, right) {
      border-right: $thickness solid $color;
    }

    @if list.index($sides, bottom) {
      border-bottom: $thickness solid $color;
    }

    @if list.index($sides, left) {
      border-left: $thickness solid $color;
    }

    @if list.index($sides, y) {
      border-top: $thickness solid $color;
      border-bottom: $thickness solid $color;
    }

    @if list.index($sides, x) {
      border-right: $thickness solid $color;
      border-left: $thickness solid $color;
    }
  }
  width: $width;
}

// -------------------------------------
//   Half background
//   Example:
//   @include bg-half(250px);
// -------------------------------------
@mixin bg-half($height: 50%, $position: 'after') {
  position: relative;
  padding-bottom: 24px;
  &:#{$position} {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -2;
    content: '';
    width: 100%;
    height: $height;
    background: var(--c_grey_100);
  }
}

@mixin media($breakpoint) {
  @media only screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin commonStyles {
  @media only screen {
    @content;
  }
}

@mixin mobileStyles {
  @media only screen and (width <= 767px) {
    @content;
  }
}

@mixin commonTabletDesktop {
  @media only screen and (width >= 768px) {
    @content;
  }
}

@mixin commonTabletMobile {
  @media only screen and (width <= 1025px) {
    @content;
  }
}

@mixin tabletStyles {
  @media only screen and (width >= 768px) and (width <= 1024px) {
    @content;
  }
}

@mixin desktopStyles {
  @media only screen and (width >= 1025px) {
    @content;
  }
}

@mixin image_filter($position: after) {
  &.with-filter,
  &.with-filter-light {
    &:#{$position} {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
    }

    &.with-filter {
      &:#{$position} {
        background: rgba(var(--c_blue_700_rgb), 0.3);
      }
    }

    &.with-filter-light {
      &:#{$position} {
        background: rgba(black, 0.1);
      }
    }
  }
}

@mixin image_filter_simple($position: after) {
  &:#{$position} {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(255, 255, 255, 70%) 0%, rgba(255, 255, 255, 0%) 100%);
  }
}

// -------------------------------------
//   layout
// -------------------------------------
// Grid
$column: 89px;
$gutter: 12px;
$max-columns: 12;

@function flex-grid($columns, $container-columns: $max-columns, $gutter-width: $gutter) {
  $width: $columns * $column + ($columns - 1) * $gutter-width;
  $container-width: $container-columns * $column + ($container-columns - 1) * $gutter-width;

  @return math.percentage(math.div($width, $container-width));
}

// -------------------------------------
//   Blurred shadow
//   Example:
//   @include shadow-blurred;
//   The param background-color is optional.
//   If not passed a background must be defined in the module
// -------------------------------------
@mixin shadow-blurred($bg-color: null) {
  position: relative;

  &::after {
    position: absolute;
    left: 8%;
    bottom: 0;
    z-index: -1;
    content: '';
    opacity: 0;
    width: 84%;
    height: 10px;
    max-height: 30%;

    //filter: blur(19px);
    box-shadow: 0 1px 38px -1px rgba(0, 0, 0, 60%);
    background-color: rgba(0, 0, 0, 24%);
    transition: opacity 0.4s ease-in-out;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }

  & > * {
    @if $bg-color {
      background-color: $bg-color;
    }
    position: relative;
  }

  // Fix for IE -- Remove unsupported filter blur
  @media all and (-ms-high-contrast: none) {
    /* IE10 IE11 */
    *::-ms-backdrop,
    & {
      transition: box-shadow 0.4s ease-in-out;

      &:hover {
        box-shadow: 0 8px 40px -5px rgba(0, 0, 0, 24%);
      }

      &::after {
        display: none;
      }
    }
  }
}

@mixin swiperCounter {
  .swiper-counter {
    @include fontSize(16);
    color: rgba(var(--c_turquoise_rgb), 0.5);
    line-height: 26px;
    text-align: right;

    span {
      margin-right: 4px;
      color: var(--c_primary);
    }
  }
}

/**
 * Mixin for interactive element styles.
 *
 * This mixin applies common styles for interactive elements,
 * including background color and text color on hover state.
 * Optionally, it can also apply a border-radius property.
 *
 * @param {Boolean} $include-border-radius - Indicates whether to include the border-radius property.
 *                                           Default is true.
 *                                           - true: Includes border-radius.
 *                                           - false: Does not include border-radius.
 *
 * @example -> Using the mixin with border-radius (default)
 * .element-default {
 *   @include interactiveElement;
 * }
 *
 * @example -> Using the mixin with explicit border-radius
 * .element-with-border-radius {
 *   @include interactiveElement(true);
 * }
 *
 * @example -> Using the mixin without border-radius
 * .element-without-border-radius {
 *   @include interactiveElement(false);
 * }
 */
@mixin interactiveElement($include-border-radius: true) {
  @if $include-border-radius {
    border-radius: var(--border-radius);
  }
  background: var(--interactive-background-hover);
  color: var(--interactive-text-hover);
}
