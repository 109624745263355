@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.agents-page {
  width: 100%;
  max-width: 1240px;
  padding: 0 20px;

  .block-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .col {
      flex-grow: 0;
    }

    .block-title {
      margin-bottom: 8px;
    }
  }

  .counter {
    @include fontSize(18);
    font-weight: var(--sans-semi-bold);
  }
}

@include media($mobile) {
  .agents-page {
    padding: 0;

    .block-header {
      flex-direction: column;
      align-items: flex-start;

      .col {
        width: 100%;

        .agents {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
}
