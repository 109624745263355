@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.agents-form {
  .validation-errors {
    position: relative;
    padding: 10px;
    margin-top: 8px;
    border: 1px solid var(--c_grey_400);
    background-color: var(--c_grey_100);

    &.hidden {
      display: none;
    }
  }

  .validation-success {
    padding: 12px;
    margin-top: 20px;
    background-color: var(--c_green_secondary);

    .text-success {
      @include fontSize(11);

      @include icon(check, before) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 23px;
        height: 22px;
        padding-top: 2px;
        padding-left: 2px;
        margin-right: 12px;
        border-radius: 100px;
        background-color: var(--c_green);
        color: var(--c_white);
      }
      display: flex;
      color: #000;
    }

    &.hidden {
      display: none;
    }
  }

  .password-row {
    .parsley-errors-list {
      li:not(.parsley-required) {
        display: none;
      }
    }
  }
}
