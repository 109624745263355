@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.home-page {
  .introduction-module {
    &.w-image {
      .textual-content {
        padding-top: 24px;
      }
    }

    .image {
      &::after {
        background: var(--c_cloud);
      }
    }

    .textual-content {
      &.list-styles {
        ul {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            &::before {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}

@include media($tablet) {
  .home-page {
    .introduction-module {
      .textual-content {
        // pendiente marcar este elemento con una clase para poder editar el modulo directamente
        p {
          &:last-child {
            padding: 13px;
            border: solid 1px var(--c_primary);
            text-align: center;
          }
        }
      }
    }
  }
}
