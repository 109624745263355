@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

@mixin fade-up-delayed($translate-y, $duration, $delay) {
  $child-delay: $delay + 0.2s;
  transform: translateY($translate-y);
  opacity: 0;
  transition: all 0s linear $delay;

  &:first-child {
    &.active {
      transform: translateY(0);
      opacity: 1;
      transition: opacity $duration ease $delay, transform $duration ease $delay;
    }
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
    transition: opacity $duration ease $child-delay, transform $duration ease $child-delay;
  }
}

.only_login {
  .session-container {
    .second-layer {
      &.login {
        transform: translateY(0);

        .row {
          transform: translateY(0);
          opacity: 1;
        }
      }

      .bt-close {
        display: none;
      }

      &.register {
        margin-top: 37px;
      }
    }
  }
}

.session-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 8001;
  width: 100%;
  height: 0;

  .page-wrapper {
    height: 0;
  }

  .first-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    transform: translateY(100vh);
    width: 100%;
    height: 100vh;
    background-color: var(--c_blue_green_100);
    transition: all 0.4s ease-out;

    &.active {
      transform: translateY(0);
    }
  }

  .second-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transform: translateY(100vh);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: var(--c_white);
    transition: all 0.5s ease-out;

    .session-page .wrapper .block-form {
      padding-top: 50px;
    }

    &.register {
      justify-content: flex-start;
    }

    &.login {
      .row.register {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }

    &.active {
      transform: translateY(0);
    }

    .bt-close {
      position: absolute;
      top: 40px;
      right: 40px;
      z-index: 20;
      cursor: pointer;
      color: var(--c_primary);
      font-size: 16px;
    }

    .row {
      @include fade-up-delayed(60px, 0.6s, 0.5s);
    }

    > .row {
      opacity: 0;
      max-width: 390px;

      &.logo {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin-bottom: 40px;

        #logo-iberostar {
          width: 219px;
          max-height: 40px;
          margin-top: 10px;
        }

        img {
          max-width: 100%;
        }
      }

      &.register {
        @include fontSize(14);
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        margin-top: 40px;
        margin-bottom: 80px;

        .pro-button {
          margin-left: 10px;
        }
      }

      .login {
        padding: 40px;
        border: 1px solid var(--c_grey_200);
        border-radius: 10px;
        box-shadow: 0 16px 27px -7px rgba(0, 0, 0, 20%);

        .pro-button {
          width: 100%;
        }

        .reset-password {
          margin-top: 6px;
        }
      }
    }
  }
}

.fancybox-slide {
  & > div {
    &.loading-register {
      width: 100%;
      max-width: 670px;
      padding: 0 0 40px;
      background-color: var(--c_white);
      text-align: center;

      &-wrapper {
        padding: 0 0 40px;
        background-color: var(--c_white);
      }

      .title {
        @include fontSize(18);
        margin-top: -10px;
        margin-bottom: 10px;
        color: var(--c_blue_700);
        line-height: 1.4;
        letter-spacing: 0;
      }

      .fancybox-close-small {
        display: none;
      }

      @include media($tablet_landscape) {
        max-width: 670px;
      }

      @include media($mobile) {
        max-width: calc(100% - 20px);
        margin: 0;
      }
    }
  }
}

.fz-field {
  border: 1px solid var(--c_grey_200);

  .show-password {
    transform: translateY(-20%);

    .toggle-password-icon {
      font-size: 24px;
    }
  }
}

.session-page {
  .wrapper {
    .block-content {
      max-width: calc(1240px / 2);
      padding: 0;
      background-color: transparent;

      &:first-child {
        .block-title {
          @include fontSize(32);
          margin: 0 0 10px;

          &::before {
            display: none;
          }
        }

        .b-description {
          p {
            font-weight: var(--sans-semi-bold);
          }
        }
      }
    }

    .block-form {
      padding-top: 80px;

      .logo {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        #logo-iberostar {
          width: 219px;
          max-height: 40px;
          margin-top: 10px;
        }

        img {
          max-width: 100%;
        }
      }

      &.login {
        margin-top: 36px;

        .introduction-module {
          margin-bottom: 8px;
        }
      }

      .introduction-module {
        cursor: pointer;
        margin: 0;

        &.close {
          .t-fb-label {
            @include icon(arrow-up-fat) {}
          }
        }

        .t-fb-label {
          @include icon(arrow-down-fat) {}
          position: relative;
          max-width: 100%;
          margin: 0;
          color: var(--c_black);

          &::after {
            position: absolute;
            top: 56%;
            right: 0;
            transform: translateY(-50%);
            color: var(--c_primary);
            font-weight: 600;
            font-size: 12px;
          }


        }
      }

      .register {
        .secondary-btn {
          margin-left: 20px;
        }

        &.go-login {
          p {
            @include fontSize(14);
            font-weight: var(--sans-semi-bold);
          }
        }
      }

      @include media($tablet) {
        height: auto;
        overflow-y: visible;
      }
    }
  }

  .content-wrapper {
    position: relative;

    .register {
      display: flex;
      flex: 0 0 100%;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      .wrapper-flash {
        margin-bottom: 20px;

        .alert-danger {
          position: relative;
          top: 0;
          width: 100%;
        }
      }
    }

    .step-error-message {
      transform: translateY(0);
      opacity: 1;
    }

    .form-wrapper {
      max-width: 391px;
      padding: 40px;
      margin: 0 auto 40px;
      border: 1px solid var(--c_grey_200);
      border-radius: 10px;
      box-shadow: 0 16px 27px -15px rgba(0, 0, 0, 20%);
      background-color: var(--c_white);

      .step-error-message {
        .alert {
          position: relative;
          top: 0;
        }
      }

      &.comercial-data,
      &.agents-last-step {
        .t-p {
          &::before {
            top: 2px;
          }
        }

        .general-conditions {
          max-width: 300px;
          margin-top: 20px;
        }
      }

      &.comercial-data {
        margin-top: -70px;
      }

      &.agents-first-step {
        margin-bottom: 20px;
      }

      .register {
        position: relative;
        justify-content: space-between;
        padding-top: 23px;

        &.go-login {
          justify-content: flex-start;

          p {
            flex-grow: 0;
          }
        }

        &::before {
          position: absolute;
          top: 0;
          content: '';
          width: 100%;
          border-top: solid 1px var(--c_grey_200);
        }

        &::after {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          content: 'o';
          width: 30px;
          background-color: var(--c_white);
          text-align: center;
        }

        .pro-button {
          min-width: 155px;
          margin-top: 1rem;
        }

        p {
          flex-grow: 1;
        }
      }

      .pro-button {
        width: 100%;
        margin-bottom: 1rem;
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .btn-primary {
          width: 100%;
        }
      }
    }
  }

  .fz-row {
    margin-bottom: 15px;

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > .t-p {
      @include fontSize(12);
      font-weight: var(--sans-semi-bold);
    }
  }

  .alert {
    position: absolute;
    top: 50px;
    right: 0;
    z-index: 9;
    width: 50%;
    margin: 0;
  }
}

.agents-form {
  .contact-data {
    border-top: solid 1px var(--c_grey_200);
  }

  .fz-row.phone {
    flex-wrap: nowrap;
  }

  .prefix {
    flex: 0 0 100px;
    height: 100%;
    border-right: 0;
  }
}

.default-layout {
  .introduction-module {
    &:first-child {
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}

.agents-second-step,
.comercial-data,
.agents-last-step {
  .agents-form.register & {
    .fz-row {
      justify-content: space-between;

      > *.fz-col {
        flex: 0 0 auto;
        width: auto;

        &.fz-large {
          width: 100%;
        }

        &.fz-medium {
          width: 70%;
        }

        &.fz-small {
          width: 50%;
        }

        &.commercial-check {
          flex: 0 1 auto;
        }
      }
    }
  }

  &.popup {
    .formikation {
      .row {
        padding-bottom: 0;
      }
    }
  }

  &.change-password {
    .introduction-module {
      .block-title {
        margin-bottom: 20px;
      }
    }
  }
}

@include commonTabletMobile {
  .only_login {
    .session-container {
      margin-top: 30px;
    }
  }

  .session-container {
    &.page-wrapper {
      height: 0;

      &.active {
        height: 100%;
      }
    }

    .first-layer {
      transform: translateY(-110vh);
    }

    .second-layer {
      transform: translateY(-110vh);
    }

    .session-page {
      .agents-form {
        &.register {
          margin-bottom: 120px;
        }
      }
    }
  }
}

@include mobileStyles {
  .only_login {
    .session-container {
      margin-top: 69px;
    }
  }

  .session-container {
    .second-layer {
      .bt-close {
        top: 20px;
        right: 20px;
      }

      > .row {
        max-width: 100%;

        .login {
          padding: 0 20px;
          border: 0;
          border-radius: 0;
          box-shadow: none;
        }

        &.register {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 0 20px;
          margin-bottom: 0;
        }
      }
    }
  }

  .session-page {
    .wrapper {
      padding: 0;

      .block-form {
        .register {
          flex-direction: column;
          padding: 0;

          .secondary-btn {
            width: calc(100% - 40px);
            margin: 20px 0 0;
          }
        }
      }
    }

    .content-wrapper {
      .form-wrapper {
        padding: 0 20px;
        border: none;
        border-radius: 0;
        box-shadow: none;

        &.comercial-data {
          margin-top: 20px;
        }
      }
    }

    .agents-form {
      .form-wrapper {
        margin-top: 20px;

        .fz-row {
          > .t-p {
            margin-bottom: 10px;
          }

          .fz-col {
            .input-text {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
