@use '../../base_css/abstracts/abstracts' as *;
@forward '../../base_css/abstracts/abstracts';

.lightbox-messages {
  width: 60%;

  .lightbox-header {
    &.with-media {
      display: flex;
      align-items: center;

      .titles {
        flex: 0 1 55%;
      }

      .media {
        margin-left: auto;
      }
    }
  }

  .landing-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bottom-actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 80px;
  }

  .t-fb-label {
    cursor: pointer;
  }

  .cta {
    text-align: center;
  }

  .s {
    display: block;
    text-align: center;

    &::after {
      @include fontSize(150);
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }

  .step {
    display: none;

    &.active {
      display: block;
    }
  }

  .next {
    display: flex;
    justify-content: flex-end;
  }
}

@include mobileStyles {
  .lightbox-messages {
    width: 90%;

    .lightbox-header {
      &.with-media {
        flex-direction: column;
      }
    }

    .bottom-actions {
      justify-content: center;
    }
  }
}
