@use '../../base_css/abstracts/abstracts' as *;

/* ------------------------------------*\
  Spinners
\*------------------------------------ */

.spinner {
  svg {
    path {
      fill: var(--c_grey_400);
    }
  }
}

// spinner 1
.spinner-sun {
  .line {
    opacity: 1;
    animation: appear 1.6s infinite;
  }

  .line0 {
    opacity: 1;
  }

  .line1 {
    animation-delay: 150ms;
  }

  .line2 {
    animation-delay: 350ms;
  }

  .line3 {
    animation-delay: 550ms;
  }

  .line4 {
    animation-delay: 750ms;
  }

  .line5 {
    animation-delay: 950ms;
  }

  .line6 {
    animation-delay: 1150ms;
  }

  .line7 {
    animation-delay: 1350ms;
  }

  .line8 {
    animation-delay: 1550ms;
  }

  .line9,
  .line10 {
    animation-delay: 1750ms;
  }
}

// Appear and vanish
@keyframes appear {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
