@use '../../../base_css/abstracts/abstracts' as *;
@forward '../../../base_css/abstracts/abstracts';

.edit-user-page {
  .wrapper {
    &.flex {
      margin-top: 60px;
    }

    .agents-form {
      margin: 24px 0;

      .section-wrap {
        padding-bottom: 24px;
        margin-bottom: 24px;
        background-color: rgba(219, 219, 219, 30%);
      }

      .title-block {
        @include fontSerifBold;
        @include fontSize(24);
        margin-bottom: 24px;
        color: var(--c_blue_700);
        line-height: 28px;
      }

      .fz-field {
        &.disabled {
          opacity: 1;
          border: 0;
          background-color: transparent;

          label {
            padding: 0 0 8px;

          }

          input {
            padding-left: 0;
            padding-bottom: 0;
            color: var(--c_black);
            text-transform: none;
          }
        }
      }

      a {
        &.l-external {
          @include fontSize(12);
          width: 100%;
          margin-top: 10px;
        }

        &.change-password-link {
          @include fontSize(14);
          margin-top: 24px;
          color: var(--c_primary);
          font-weight: var(--sans-bold);
        }
      }

      .contact-data {
        @include fontSize(14);
        width: 100%;
        padding-top: 24px;
        margin: 24px 0;
        border-top: solid 1px var(--c_grey_200);
        color: var(--c_blue_700);
        font-weight: var(--sans-semi-bold);
        text-transform: uppercase;
      }

      .fz-row {
        justify-content: flex-start;

        & > * {
          &.fz-col {
            margin-right: 24px;

            .t-fb-label {
              @include fontSize(11);
            }

            &:last-child {
              margin-right: 0;
            }

            &.fz-disabled {
              flex-basis: auto;
              flex-grow: 1;
              width: auto;
            }

            &.fz-small {
              flex-basis: calc(27% - 16px);
              max-width: 200px;
            }

            &.fz-medium {
              flex-basis: calc(33.3% - 16px);
            }

            &.fz-large {
              flex-basis: calc(50% - 16px);
              max-width: 364px;
            }
          }
        }

        &.fz-row-medium {
          & > * {
            &.fz-col {
              flex-basis: calc(33.3% - 16px);
            }
          }
        }

        &.fz-row-large {
          & > * {
            &.fz-col {
              flex-basis: calc(50% - 16px);
              max-width: 364px;
            }
          }
        }

        &.fz-row-email-phone {
          & > * {
            &.fz-col:first-child {
              flex-basis: calc(50% - 16px);
              max-width: 364px;
            }

            &.fz-col:last-child {
              flex-basis: calc(40% - 16px);
            }
          }
        }

        &.fz-row-dir-poscode {
          & > * {
            &.fz-col:first-child {
              flex-basis: calc(50% - 16px);
              max-width: 364px;
            }

            &.fz-col:last-child {
              flex-basis: calc(27% - 16px);
              max-width: 200px;
            }
          }
        }
      }

      & > * {
        .fz-col.phone {
          display: flex;
          height: 100%;
        }
      }

      .action {
        justify-content: flex-end;
      }

      .permanent-login {
        display: inline-block;
        width: 33.3%;
        padding: 16px;
        background-color: var(--c_white);

        label {
          @include fontSize(14);
          font-weight: normal;
        }
      }
    }

    .wrapper-flash {
      padding: 0;

      .alert {
        width: 100%;
      }
    }
  }
}

.fancybox-slide {
  & > div {
    &.ok-password-change {
      width: 100%;
      max-width: 670px;
      background-color: var(--c_white);
      text-align: center;

      .ok-password-change-wrapper {
        background-color: var(--c_white);
      }

      .ok-password-change-header {
        margin-bottom: 24px;

        .s {
          color: var(--c_primary);
          font-size: 64px;
        }
      }

      .title {
        @include fontSize(18);
        margin-top: -10px;
        margin-bottom: 10px;
        color: var(--c_blue_700);
        line-height: 1.4;
        letter-spacing: 0;
      }

      .text {
        margin-bottom: 24px;
      }
    }
  }
}

@include media($tablet_landscape) {
  .fancybox-slide {
    & > div {
      &.ok-password-change {
        max-width: 670px;
      }
    }
  }
}

@include media($mobile) {
  .edit-user-page {
    .agents-form {
      .fz-row {
        & > * {
          .fz-col {
            .fz-small {
              flex-basis: calc(30% - 16px);
            }
          }
        }
      }
    }
  }
}

@include media($mobile) {
  .edit-user-page {
    .agents-form {
      .permanent-login {
        width: 55%;
        margin-top: 24px;
        margin-bottom: 20px;
      }

      .contact-data {
        padding-top: 20px;
        margin: 0 0 20px;
      }

      .fz-row {
        & > * {
          &.fz-col {
            margin-right: 0;

            &.fz-disabled {
              flex-basis: auto;
              flex-grow: 1;
              width: 100%;
            }

            &.fz-small {
              flex-basis: calc(60% - 16px);
            }

            &.fz-medium {
              flex-basis: calc(80% - 16px);
            }

            &.fz-large {
              flex-basis: calc(100%);
            }
          }
        }

        &.fz-row-medium {
          & > * {
            &.fz-col {
              flex-basis: calc(80% - 16px);
            }
          }
        }

        &.fz-row-large {
          & > * {
            &.fz-col {
              flex-basis: calc(100%);
            }
          }
        }

        &.fz-row-email-phone {
          & > * {
            &.fz-col:first-child {
              flex-basis: calc(100%);
            }

            &.fz-col:last-child {
              flex-basis: calc(80% - 16px);
            }
          }
        }

        &.fz-row-dir-poscode {
          & > * {
            &.fz-col:first-child {
              flex-basis: calc(100%);
            }

            &.fz-col:last-child {
              flex-basis: calc(60% - 16px);
            }
          }
        }
      }

      a {
        &.l-external {
          margin-top: -10px;
          margin-bottom: 20px;

          &.margin-especial {
            margin-top: 10px;
            margin-bottom: -10px;
          }
        }
      }
    }
  }

  .fancybox-slide {
    & > div {
      &.ok-password-change {
        max-width: calc(100% - 20px);
        margin: 0;
      }
    }
  }
}

@include media($small-mobile) {
  .edit-user-page {
    .block-title {
      padding: 0 16px;
    }

    .wrapper {
      padding: 0;
    }

    .agents-form {
      .action {
        * {
          width: 100%;
        }
      }

      .section-wrap {
        padding: 20px 16px;
      }

      .permanent-login {
        width: 80%;
      }
    }
  }
}
