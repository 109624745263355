@use 'sass:map';
@use 'variables' as *;
@forward 'variables';
@use '../../abstracts/variables' as *;

@mixin custom-icon {
  font-weight: normal;

  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icons-font-family}' !important;
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-transform: none;
  speak: never;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Mixin for adding icons to element without need of adding classes
@mixin icon($icon: false, $position: after, $size: '', $styles: true) {
  // Either a :before or :after pseudo-element, default to :after
  &::#{$position} {
    @if $icon {
      content: '#{map.get($icons, $icon)}';
    }

    @if $styles {
      @include custom-icon;
    }

    // Include size icon
    @if $size == 'xs' {
      font-size: 12px;
    } @else if $size == 's' {
      font-size: 16px;
    } @else if $size == 'm' {
      font-size: 24px;

      @media only screen and (max-width: $mobile) {
        font-size: 20px;
      }
    } @else if $size == 'l' {
      font-size: 32px;

      @media only screen and (max-width: $mobile) {
        font-size: 28px;
      }
    } @else if $size == 'xl' {
      font-size: 42px;

      @media only screen and (max-width: $mobile) {
        font-size: 36px;
      }
    } @else if $size == 'xxl' {
      font-size: 64px;

      @media only screen and (max-width: $mobile) {
        font-size: 48px;
      }
    }

    @content;
  }
}
