@use '../../../base_css/abstracts/abstracts' as *;

.my-iberostar-page {
  background: var(--c_white);

  .wrapper {
    &.flex {
      padding: 60px 20px 80px;
    }
  }

  .wrapper-flash {
    padding: 0;

    .alert {
      width: 100%;
    }
  }

  .wrapper.no-padding-right {
    padding-right: 0;
  }

  .row-title {
    margin-bottom: 10px;
  }
}

[data-empty='true'] {
  padding: 0;

  .cookies-warning {
    display: none !important;
  }
}

.fancybox-slide--iframe {
  .fancybox-content {
    overflow: auto;
  }
}

@include commonTabletDesktop {
  .my-iberostar-page {
    .quote-index-list {
      .horizontal-card {
        grid-template-columns: auto;
      }
    }
  }
}

@include media($mobile) {
  .fancybox-slide--iframe .fancybox-content {
    max-width: calc(100% - 20px);
    max-height: calc(100% - 80px);
    margin: 0 auto;
  }

  .my-iberostar-page {
    .wrapper.no-padding-right {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

@include media($small-mobile) {
  [data-empty='true'] {
    .page-wrapper {
      min-width: 100%;
    }
  }
}
